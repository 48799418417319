import {Alert, AlertTitle} from '@mui/lab'
import {Grid} from '@mui/material'
import * as Sentry from '@sentry/node'
import {NextPage, NextPageContext} from 'next'

import {Breadcrumbs} from 'core/components/breadcrumbs'

interface ErrorPageProps {
    statusCode: number
    getInitialPropsHasRun?: boolean
    err?: Error | null
}

export const ErrorPage: NextPage<ErrorPageProps> = ({statusCode = 500, getInitialPropsHasRun, err}) => {
    if (!getInitialPropsHasRun && err instanceof Error) {
        // getInitialProps is not called in case of https://github.com/zeit/next.js/issues/8592.
        // As a workaround, we pass err via _app.js so it can be captured.
        Sentry.captureException(err)
    }

    let title = ''
    let description = ''

    if (statusCode === 400) {
        title = 'Solicitud malformada'
        description = 'La solicitud enviada contiene errores y no puede ser procesada.'
    }

    if (statusCode === 401) {
        title = 'Autenticación requerida'
        description = 'La página solicitada requiere autenticación.'
    }

    if (statusCode === 403) {
        title = 'Permiso denegado'
        description = 'No se puede ver la página solicitada debido a permisos insuficientes.'
    }

    if (statusCode === 404) {
        title = 'Página no encontrada'
        description = 'La página que estás buscando no existe.'
    }

    if (statusCode === 500) {
        title = 'Error interno en el servidor'
        description = 'No se puede procesar la solicitud ahora.'
    }

    return (
        <Grid container={true} spacing={4}>
            <Grid item={true} xs={12}>
                <Breadcrumbs path={[{label: title}]} />
            </Grid>
            <Grid item={true} xs={12}>
                <Alert severity='error'>
                    <AlertTitle>{title}</AlertTitle>
                    {description}
                </Alert>
            </Grid>
        </Grid>
    )
}

ErrorPage.getInitialProps = ({res, err, asPath}: NextPageContext) => {
    const statusCode = res?.statusCode || err?.statusCode || 404

    // Workaround for https://github.com/zeit/next.js/issues/8592,
    // mark when getInitialProps has run.
    const getInitialPropsHasRun = true

    const props = {statusCode, getInitialPropsHasRun, err}

    // Running on the server.
    if (res) {
        // Do not capture an exception in Sentry for 404s.
        if (res.statusCode !== 404 && err) {
            Sentry.captureException(err)
        }
        return props
    }

    // Running on the client (browser).
    if (err) {
        Sentry.captureException(err)
        return props
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry.
    Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))

    return props
}
